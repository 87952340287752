import React from "react";
import { Layout } from "../components/Layout/Layout";
import { graphql, PageRendererProps } from "gatsby";

import { PartialPhoto } from "../types/photos";
import { Hero } from "../components/Hero/Hero";
import { SEO } from "../components/SEO/SEO";

import styles from "./blog.module.scss";
import { Card } from "../components/Card/Card";

interface PhotosPageProps extends PageRendererProps {
  data: {
    allPhotos: {
      nodes: PartialPhoto[];
    };
  };
}

export const PhotosPage = ({ data: { allPhotos } }: PhotosPageProps) => {
  return (
    <Layout>
      <SEO title="Photos" />
      <Hero heading="Photos" lead="Nature and landscape photography" />
      <nav aria-label="Photos" className="container">
        <ol className={styles.list}>
          {allPhotos.nodes.map((photo) => (
            <li key={photo.id}>
              <Card item={photo} basePath="photos" />
            </li>
          ))}
        </ol>
      </nav>
    </Layout>
  );
};

export default PhotosPage;

export const allPhotosQuery = graphql`
  {
    allPhotos: allMdx(
      filter: { fileAbsolutePath: { regex: "/photos/" } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      nodes {
        ...PartialPhoto
      }
    }
  }
`;
