import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";

import styles from "./Card.module.scss";
import { BasePage } from "../../types/common";
import { TagList } from "../TagList/TagList";

interface CardProps {
  item: BasePage;
  basePath: string;
}

export const Card = ({ item, basePath }: CardProps) => {
  const { frontmatter, fields } = item;
  const { thumb, description, date, dateFormatted, categories } = frontmatter;

  return (
    <article className={styles.wrapper}>
      {thumb && (
        <Img sizes={{ ...thumb.childImageSharp.fluid, aspectRatio: 16 / 9 }} />
      )}
      <div className={styles.textContainer}>
        <h2 className={styles.heading}>
          <Link to={`/${basePath}${fields.slug}`}>{frontmatter.title}</Link>
        </h2>
        <p className={styles.lead}>{description}</p>
        {categories && categories.length > 0 && <TagList tags={categories} />}
      </div>
      <p className={styles.date}>
        <time dateTime={date}>{dateFormatted}</time>
      </p>
    </article>
  );
};
