import React from "react";

import styles from "./TagList.module.scss";

type TagListProps = {
  tags: string[];
};

export const TagList = ({ tags }: TagListProps) =>
  tags.length > 0 ? (
    <ul className={styles.list}>
      {tags.map((t) => (
        <li key={t}>
          <span>{t}</span>
        </li>
      ))}
    </ul>
  ) : null;
